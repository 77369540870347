import React, { useEffect } from "react";
import { scrollToTop } from "../../helpers/helpers";
import useTranslations from "../../hoc/useTranslations";
import Description from "../../components/Description/Description";
import Heading from "../../components/Heading/Heading";
import SEO from "../../components/seo";
import { PAGE } from "../../helpers/constants";
import "./TermsConditions.scss";

export default function TermsConditions() {
	const { termsConditions, termsConditionsParagraphs } = useTranslations();

	useEffect(() => {
		scrollToTop();
	}, []);
	return (
		<>
			<SEO page={PAGE.TERMS} />
			<section className="termsConditions__section">
				{termsConditions.map(({ title, descriptions }, index) => (
					<article key={index} className="termsConditions__section__article">
						<Heading className="termsConditions__section__heading" type={2}>
							{title}
						</Heading>
						{descriptions.map(description => (
							<Description key={description}>{description}</Description>
						))}
					</article>
				))}
				{termsConditionsParagraphs.map(({ title, descriptions }, index) => (
					<article key={index} className="termsConditions__section__article">
						<Heading className="termsConditions__section__heading__paragraphs" type={2}>
							<span>{`§ ${index + 1}`}</span>
							{title}
						</Heading>
						{descriptions.map((description, index) => (
							<ol key={index}>
								<Description key={index}>{`${index + 1}. ${description.title}`}</Description>
								{description.subdescriptions ? (
									<>
										{description.subdescriptions.map(subdescription => (
											<li key={subdescription}>
												<p>{subdescription}</p>
											</li>
										))}
									</>
								) : null}
							</ol>
						))}
					</article>
				))}
			</section>
		</>
	);
}
