import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { checkTranslationTags } from "../../helpers/helpers";

import "./Description.scss";

const Description = ({ className, id, children }) => (
	<p
		id={id}
		className={clsx("description", className)}
		dangerouslySetInnerHTML={checkTranslationTags(children)}
	/>
);

Description.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	children: PropTypes.string.isRequired,
};

Description.defaultProps = {
	className: "",
	id: "",
};

export default Description;
